import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ContactMap from '../components/ContactMap';
import PageContent from '../components/PageContent';

const ContactPage = ({
  data: {
    contentfulContact: {
      title,
      embeddedMapUrl: { embeddedMapUrl },
      content,
    },
  },
}) => {
  return (
    <Layout>
      <main>
        <ContactMap title={title} mapUrl={embeddedMapUrl} />
        <PageContent title={title} content={content} isContact={true} />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query ContactQuery {
    contentfulContact {
      title
      embeddedMapUrl {
        embeddedMapUrl
      }
      content {
        raw
      }
      metaDescription
      slug
    }
  }
`;

export default ContactPage;

export const Head = ({
  data: {
    contentfulContact: { title, metaDescription },
  },
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
    </>
  );
};
