import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Container } from './ui';
import Breadcrumbs from './Breadcrumbs';

const StyledContactMap = styled.section``;

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
  position: relative;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-left: 30px;

  ${minBreakpointQuery.xlarge`
    margin-left: 54px;
  `}
`;

const StyledMapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  padding-top: 32%;
  overflow: hidden;
`;

const StyledMap = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: calc(100% + 350px);
  height: calc(100% + 150px);
  border: 0;
  margin-left: -350px;
  margin-bottom: -150px;
`;

const ContactMap = ({ title, mapUrl }) => (
  <StyledContactMap>
    <StyledContainer wide={true}>
      <StyledBreadcrumbs currentPageTitle={title} />
      <StyledMapWrapper>
        <StyledMap
          src={mapUrl}
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></StyledMap>
      </StyledMapWrapper>
    </StyledContainer>
  </StyledContactMap>
);

export default ContactMap;
